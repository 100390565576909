import React, { useCallback } from 'react';
import RichText from '../components/RichText';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Link as GatsbyLink } from 'gatsby';
import { Background } from 'react-imgix';
import {
  signInDialogOpenState,
  newEnquiryDialogOpenState,
} from '../../common/States';
import { useSetRecoilState } from 'recoil';

const useStyles = makeStyles(theme => {
  return {
    overlay: {
      display: 'block',
      backgroundColor: props => {
        return !!props.image_overlay_color
          ? `${props.image_overlay_color}`
          : '';
      },
      opacity: props => {
        return props.image_overlay_opacity == null
          ? 0
          : props.image_overlay_opacity * 0.01;
      },
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
    },
    backgroundImage: {
      position: 'relative',
      backgroundPosition: props => {
        return props.image_object_position == null
          ? 'center'
          : props.image_object_position;
      },
      [theme.breakpoints.down('lg')]: {
        backgroundAttachment: 'scroll',
      },
      [`${theme.breakpoints.up('lg')}`]: {
        backgroundAttachment: props =>
          props.background_fixed ? 'fixed' : 'scroll',
      },
    },
  };
});

const Hero = ({ data }) => {
  const setSignInDialogOpen = useSetRecoilState(signInDialogOpenState);
  const setNewEnquiryDialogOpen = useSetRecoilState(newEnquiryDialogOpenState);

  const callToAction = useCallback(callToActionType => {
    console.log(callToActionType);
    switch (callToActionType) {
      case 'Traveller Sign In':
        setSignInDialogOpen(true);
        break;
      case 'Traveller Sign Up':
        setSignInDialogOpen(true);
        break;
      case 'Enquiry':
        setNewEnquiryDialogOpen(true);
        break;
      default:
        console.log('Unknown call to action ' + callToActionType);
    }
  }, []);

  const { fields, primary } = data;
  const {
    background_image,
    heading,
    heading_color,
    heading_align_text,
    heading_pt,
    heading_pb,
    image_overlay_color,
    image_overlay_opacity,
    image_object_position,
    min_height,
    background_fixed,
    justify_content,
  } = primary;
  const classes = useStyles({
    min_height,
    image_object_position,
    image_overlay_opacity,
    image_overlay_color,
    background_image,
    background_fixed,
  });

  let headingPT = 0;
  if (!!heading_pt) {
    headingPT = {
      xs: heading_pt * 0.2,
      sm: heading_pt * 0.5,
      lg: heading_pt,
    };
  }
  let headingPB = 0;
  if (!!heading_pb) {
    headingPB = {
      xs: heading_pb * 0.2,
      sm: heading_pb * 0.5,
      lg: heading_pb,
    };
  }
  return (
    <>
      <Background
        src={background_image ? background_image.url : ''}
        className={classes.backgroundImage}
        sizes="100vw"
      >
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent={justify_content}
          minHeight={min_height}
          zIndex={1}
        >
          <Container maxWidth="md">
            <Box
              textAlign={heading_align_text}
              pt={headingPT}
              pb={headingPB}
              className={classes.heading}
              color={heading_color}
            >
              <RichText render={heading} />
            </Box>
          </Container>
          {fields.map((item, index) => {
            const buttonAlign = item.button_align;
            const buttonColor = item.button_color;
            const buttonLinkType = item.button_link_type;
            const buttonText = item.button_text;
            const buttonSize = item.button_size;
            const buttonVariant = item.button_variant;
            let tagLinePT = 0;
            if (!!item.tag_line_pt) {
              tagLinePT = {
                xs: item.tag_line_pt * 0.2,
                sm: item.tag_line_pt * 0.5,
                lg: item.tag_line_pt,
              };
            }
            let tagLinePB = 0;
            if (!!item.tag_line_pb) {
              tagLinePB = {
                xs: item.tag_line_pb * 0.2,
                sm: item.tag_line_pb * 0.5,
                lg: item.tag_line_pb,
              };
            }
            return (
              <Container maxWidth="md" key={index}>
                <Box
                  textAlign={item.tag_line_align_text}
                  pt={tagLinePT}
                  pb={tagLinePB}
                  className={classes.tagLine}
                  color={item.tag_line_color}
                >
                  <RichText render={item.tag_line} />
                  {!!buttonText && (
                    <Box textAlign={buttonAlign} pt={4} width="100%">
                      <Button
                        size={buttonSize}
                        variant={buttonVariant}
                        color={buttonColor}
                        className={classes.button}
                        onClick={() => {
                          callToAction(buttonLinkType);
                        }}
                      >
                        {buttonText}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Container>
            );
          })}
        </Box>
        <Box className={classes.overlay}></Box>
      </Background>
    </>
  );
};

export default Hero;
