import React from 'react';
import SmallBanner from '../slices/SmallBanner';
import TextSection from '../slices/TextSection';
import Hero from '../slices/Hero';
import InformationGrid from '../slices/InformationGrid';
import SplitInfoAndImage from '../slices/SplitInfoAndImage';
import CardGrid from '../slices/CardGrid';
import InfoWithBackgroundImage from '../slices/InfoWithBackgroundImage';
import CarouselSection from '../slices/CarouselSection';
import HomeCarouselSection from '../slices/HomeCarouselSection';

export default React.memo(({ body }) => {
  if (!body || body.length === 0) {
    return null;
  }
  const slices = body.map((item, index) => {
    switch (item.type) {
      // These are the API IDs of the slices
      case 'small_banner':
        return <SmallBanner key={index} data={item} />;
      case 'hero':
        return <Hero key={index} data={item} />;
      case 'information_grid':
        return <InformationGrid key={index} data={item} />;
      case 'split_info_and_image':
        return <SplitInfoAndImage key={index} data={item} />;
      case 'card_grid':
        return <CardGrid key={index} data={item} />;
      case 'info_with_background_image':
        return <InfoWithBackgroundImage key={index} data={item} />;
      case 'text_section':
        return <TextSection key={index} data={item} />;
      case 'carousel':
        return <CarouselSection key={index} data={item} />;
      case 'home_carousel':
        return <HomeCarouselSection key={index} data={item} />;

      // case 'code_block':
      //   return <CodeBlock key={s.id} input={s} />
      // case 'image':
      //   return <Image key={s.id} input={s} />
      // case 'quote':
      //   return <Quote key={s.id} input={s} />
      default:
        return null;
    }
  });
  return <>{slices}</>;
});
