import React, { useRef, useEffect, useState, useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { pauseAnimationsState } from '../../../common/States';
import { useRecoilValue } from 'recoil';
import { Background } from 'react-imgix';
import RichText from '../RichText';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import gsap from 'gsap';
import CSSPlugin from 'gsap/CSSPlugin';
import {
  signInDialogOpenState,
  newEnquiryDialogOpenState,
} from '../../../common/States';
import { useSetRecoilState } from 'recoil';

import SignInForm from '../SignInForm';
import SignUpForm from '../SignInForm/SignUpForm';
import ForgotPasswordForm from '../SignInForm/ForgotPasswordForm';
import ConfirmForgotPasswordForm from '../SignInForm/ConfirmForgotPasswordForm';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';

import useMediaQuery from '@material-ui/core/useMediaQuery';
gsap.registerPlugin(CSSPlugin);

const useStyles = makeStyles(theme => ({
  overlay: {
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
  },
  backgroundImage: {
    position: 'absolute',
    display: 'block',
    width: '100%',
    height: '100%',
    top: 0,
    opacity: 0,
  },
  indicators: {
    width: '100%',
    marginTop: '10px',
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
  },
  indicator: {
    fontSize: '15px',
    cursor: 'pointer',
    transition: '200ms',
    color: '#afafaf',
    '&:hover': {
      color: '#1f1f1f',
    },
    '&:active': {
      color: '#1f1f1f',
    },
  },
  active: {
    color: '#494949',
  },
  buttonWrapper: {
    position: 'absolute',
    height: '100px',
    backgroundColor: 'transparent',
    top: 'calc(50% - 70px)',
    '&:hover': {
      '& $button': {
        backgroundColor: 'black',
        filter: 'brightness(120%)',
        opacity: 0.4,
      },
    },
  },
  fullHeightHoverWrapper: {
    height: 'calc(100% - 20px - 10px) !important',
    top: '0 !important',
  },
  button: {
    margin: '0 10px',
    position: 'relative',
    backgroundColor: '#494949',
    top: '30px',
    color: 'white',
    fontSize: '30px',
    transition: '200ms',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.6 !important',
    },
    zIndex: 1,
  },
  fullHeightHoverButton: {
    top: 'calc(50% - 20px) !important',
  },
  buttonVisible: {
    opacity: '0.6',
  },
  buttonHidden: {
    opacity: '0',
  },
  next: {
    right: 0,
  },
  prev: {
    left: 0,
  },
  repeatImage: {
    maxWidth: 300,
    [theme.breakpoints.up('md')]: {
      transform: 'translateY(-50px)',
    },
  },
  signInHolder: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 360,
    },
    paddingTop: theme.spacing(2),
  },
}));

const HomeCarousel = ({
  data,
  min_height,
  interval,
  animation_type,
  auto_play,
  show_indicators,
  repeat_image,
  with_login_type,
  default_height,
}) => {
  const pauseAnimations = useRecoilValue(pauseAnimationsState);
  const pauseAnimationsRef = useRef(pauseAnimations);
  const classes = useStyles();
  const slidesRef = useRef([]);
  const textsRef = useRef([]);
  const [tabSelection, setTabSelection] = React.useState(0);
  const handleTabChange = useCallback((event, newValue) => {
    setTabSelection(newValue);
  }, []);

  const currentSlide = useRef(0);

  const [active, setActive] = useState(0);
  const [screenToShow, setScreenToShow] = useState('signIn');

  const buttonCssClassValue = `${classes.button} ${classes.buttonVisible}`;
  const buttonWrapperCssClassValue = `${classes.buttonWrapper}`;
  const timer = useRef(null);
  const container = useRef(null);

  const setSignInDialogOpen = useSetRecoilState(signInDialogOpenState);
  const setNewEnquiryDialogOpen = useSetRecoilState(newEnquiryDialogOpenState);

  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    pauseAnimationsRef.current = pauseAnimations;
  }, [pauseAnimations]);

  const start = useCallback(() => {
    if (!auto_play) {
      return;
    }
    clearTimeout(timer.current);
    timer.current = setInterval(() => {
      if (pauseAnimationsRef.current) {
        return;
      }
      gsap.to(slidesRef.current[currentSlide.current], {
        opacity: 0,
        duration: 2,
      });
      gsap.to(textsRef.current[currentSlide.current], {
        xPercent: -100,
        duration: 2,
        ease: 'power2.out',
      });
      currentSlide.current =
        (currentSlide.current + 1) % slidesRef.current.length;

      gsap.to(slidesRef.current[currentSlide.current], {
        opacity: 1,
        duration: 2,
      });
      gsap.set(textsRef.current[currentSlide.current], {
        xPercent: 100,
      });
      gsap.to(textsRef.current[currentSlide.current], {
        xPercent: 0,
        duration: 2,
        ease: 'power2.out',
      });
      setActive(currentSlide.current);
    }, interval + 2000);

    return () => {
      clearTimeout(timer.current);
    };
  }, [pauseAnimations]);

  const next = useCallback(() => {
    pause();
    gsap.to(slidesRef.current[currentSlide.current], {
      opacity: 0,
      duration: 1,
    });
    gsap.to(textsRef.current[currentSlide.current], {
      xPercent: -100,
      duration: 1,
      ease: 'power2.out',
    });

    currentSlide.current =
      (currentSlide.current + 1) % slidesRef.current.length;
    gsap.to(slidesRef.current[currentSlide.current], {
      opacity: 1,
      duration: 1,
    });
    gsap.set(textsRef.current[currentSlide.current], {
      xPercent: 100,
    });
    gsap.to(textsRef.current[currentSlide.current], {
      xPercent: 0,
      duration: 1,
      ease: 'power2.out',
    });
    setActive(currentSlide.current);
  });

  const previous = useCallback(() => {
    pause();

    gsap.to(slidesRef.current[currentSlide.current], {
      opacity: 0,
      duration: 1,
    });
    gsap.to(textsRef.current[currentSlide.current], {
      xPercent: 100,
      duration: 1,
      ease: 'power2.out',
    });

    currentSlide.current =
      (currentSlide.current + slidesRef.current.length - 1) %
      slidesRef.current.length;
    gsap.to(slidesRef.current[currentSlide.current], {
      opacity: 1,
      duration: 1,
    });
    gsap.set(textsRef.current[currentSlide.current], {
      xPercent: -100,
    });
    gsap.to(textsRef.current[currentSlide.current], {
      xPercent: 0,
      duration: 1,
      ease: 'power2.out',
    });
    setActive(currentSlide.current);
  });

  const pause = useCallback(() => {
    clearTimeout(timer.current);
  }, []);

  const gotTo = useCallback(index => {
    if (index !== currentSlide.current) {
      pause();
      const previousIndex = currentSlide.current;
      gsap.to(slidesRef.current[currentSlide.current], {
        opacity: 0,
        duration: 1,
      });
      gsap.to(textsRef.current[currentSlide.current], {
        xPercent: index < previousIndex ? 100 : -100,
        duration: 1,
        ease: 'power2.out',
      });
      currentSlide.current = index;
      gsap.to(slidesRef.current[currentSlide.current], {
        opacity: 1,
        duration: 1,
      });
      gsap.set(textsRef.current[currentSlide.current], {
        xPercent: index < previousIndex ? -100 : 100,
      });
      gsap.to(textsRef.current[currentSlide.current], {
        xPercent: 0,
        duration: 1,
        ease: 'power2.out',
      });

      setActive(currentSlide.current);
    }
  });

  useEffect(() => {
    console.log({ textsRef: textsRef.current });
    start();
    container.current.addEventListener(
      'mouseover',
      function (event) {
        pause();
      },
      false
    );
    container.current.addEventListener(
      'mouseleave',
      function (event) {
        start();
      },
      false
    );
  }, []);

  const callToAction = useCallback(callToActionType => {
    console.log(callToActionType);
    switch (callToActionType) {
      case 'Traveller Sign In':
        setSignInDialogOpen(true);
        break;
      case 'Traveller Sign Up':
        setSignInDialogOpen(true);
        break;
      case 'Enquiry':
        setNewEnquiryDialogOpen(true);
        break;
      default:
        console.log('Unknown call to action ' + callToActionType);
    }
  }, []);

  return (
    <>
      <Box
        ref={container}
        position="relative"
        minHeight={isSmallScreen ? 'auto' : min_height}
        height={default_height}
      >
        {data.map((item, index) => {
          const {
            align_text,
            button_align,
            button_color,
            button_link_type,
            button_size,
            button_text,
            button_variant,
            image,
            image_object_position,
            image_overlay_color,
            image_overlay_opacity,
            justify_content,
            text,
            text_color,
          } = item;
          return (
            <Background
              key={'CarouselCard' + index}
              src={image.url}
              className={classes.backgroundImage}
              sizes={default_height}
              ref={el => {
                slidesRef.current[index] = el === null ? null : el._node;
              }}
              htmlAttributes={{
                style: { opacity: index === 0 ? 1 : 0 },
              }}
            >
              <Box position="relative" zIndex={1}>
                <Container maxWidth={with_login_type === 'none' ? 'md' : false}>
                  {(with_login_type === 'none' || isSmallScreen) && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent={justify_content}
                      alignItems="center"
                      minHeight={isSmallScreen ? 'auto' : min_height}
                      height={default_height}
                    >
                      <Box
                        textAlign={align_text}
                        color={text_color}
                        width="100%"
                      >
                        {!isSmallScreen && (
                          <Box>
                            <img
                              className={classes.repeatImage}
                              src={repeat_image?.url}
                            />
                          </Box>
                        )}
                        <Box
                          ref={el => {
                            textsRef.current[index] = el;
                          }}
                        >
                          <RichText render={text} />
                        </Box>
                      </Box>

                      {button_text?.length > 0 && (
                        <Box textAlign={button_align} pt={4} width="100%">
                          <Button
                            size={button_size}
                            variant={button_variant}
                            color={button_color}
                            onClick={() => {
                              callToAction(button_link_type);
                            }}
                          >
                            {button_text}
                          </Button>
                        </Box>
                      )}
                    </Box>
                  )}

                  {with_login_type !== 'none' && !isSmallScreen && (
                    <Box display="flex">
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent={justify_content}
                        alignItems="center"
                        minHeight={min_height}
                        height={default_height}
                        width="60%"
                        px={4}
                      >
                        <Box
                          textAlign={align_text}
                          color={text_color}
                          width="100%"
                        >
                          {!isSmallScreen && (
                            <Box>
                              <img
                                className={classes.repeatImage}
                                src={repeat_image?.url}
                              />
                            </Box>
                          )}
                          <Box
                            ref={el => {
                              textsRef.current[index] = el;
                            }}
                          >
                            <RichText render={text} />
                          </Box>
                        </Box>

                        {button_text?.length > 0 && (
                          <Box textAlign={button_align} pt={4} width="100%">
                            <Button
                              size={button_size}
                              variant={button_variant}
                              color={button_color}
                              onClick={() => {
                                callToAction(button_link_type);
                              }}
                            >
                              {button_text}
                            </Button>
                          </Box>
                        )}
                      </Box>
                      <Box
                        width="40%"
                        justifyContent="flex-start"
                        alignItems="center"
                        display="flex"
                      >
                        <Paper elevation={5} className={classes.signInHolder}>
                          <Box py={2} display="flex" justifyContent="center">
                            <Tabs
                              value={tabSelection}
                              indicatorColor="primary"
                              textColor="primary"
                              onChange={handleTabChange}
                              aria-label="disabled tabs example"
                            >
                              <Tab label="Traveller" />
                              <Tab label="Travel Expert" />
                            </Tabs>
                          </Box>

                          {screenToShow === 'signIn' && (
                            <SignInForm setScreenToShow={setScreenToShow} />
                          )}
                          {screenToShow === 'signUp' && (
                            <SignUpForm
                              setScreenToShow={setScreenToShow}
                              accountTypeIndex={tabSelection}
                            />
                          )}
                          {screenToShow === 'forgotPassword' && (
                            <ForgotPasswordForm
                              setScreenToShow={setScreenToShow}
                            />
                          )}
                          {screenToShow === 'confirmForgotPassword' && (
                            <ConfirmForgotPasswordForm
                              setScreenToShow={setScreenToShow}
                            />
                          )}
                        </Paper>
                      </Box>
                    </Box>
                  )}
                </Container>
              </Box>
              <Box
                zIndex={0}
                className={classes.overlay}
                style={{
                  backgroundColor: !!image_overlay_color
                    ? `${image_overlay_color}`
                    : '',
                  opacity:
                    image_overlay_opacity == null
                      ? 0
                      : image_overlay_opacity * 0.01,
                }}
              ></Box>
            </Background>
          );
        })}
        <div className={`${buttonWrapperCssClassValue} ${classes.next}`}>
          <IconButton
            className={`${buttonCssClassValue} ${classes.next}`}
            onClick={next}
            aria-label="Next"
          >
            <NavigateNextIcon />
          </IconButton>
        </div>

        <div className={`${buttonWrapperCssClassValue} ${classes.prev}`}>
          <IconButton
            className={`${buttonCssClassValue}  ${classes.prev}`}
            onClick={previous}
            aria-label="Previous"
          >
            <NavigateBeforeIcon />
          </IconButton>
        </div>

        {show_indicators && (
          <Indicators
            classes={classes}
            length={data.length}
            active={active}
            press={index => {
              gotTo(index);
            }}
          />
        )}
      </Box>
      {with_login_type !== 'none' && isSmallScreen && (
        <Box
          width="100%"
          justifyContent="center"
          alignItems="center"
          display="flex"
          py={5}
          flexDirection="column"
          bgcolor="white"
        >
          <Box py={2} display="flex" justifyContent="center">
            <Tabs
              value={tabSelection}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              aria-label="disabled tabs example"
            >
              <Tab label="Traveller" />
              <Tab label="Travel Expert" />
            </Tabs>
          </Box>

          {screenToShow === 'signIn' && (
            <SignInForm setScreenToShow={setScreenToShow} />
          )}
          {screenToShow === 'signUp' && (
            <SignUpForm
              setScreenToShow={setScreenToShow}
              accountTypeIndex={tabSelection}
            />
          )}
          {screenToShow === 'forgotPassword' && (
            <ForgotPasswordForm setScreenToShow={setScreenToShow} />
          )}
          {screenToShow === 'confirmForgotPassword' && (
            <ConfirmForgotPasswordForm setScreenToShow={setScreenToShow} />
          )}
        </Box>
      )}
    </>
  );
};

const Indicators = props => {
  const classes = props.classes;

  let indicators = [];
  for (let i = 0; i < props.length; i++) {
    const className =
      i === props.active
        ? `${classes.indicator} ${classes.active}`
        : `${classes.indicator}`;
    const item = (
      <FiberManualRecordIcon
        key={i}
        size="small"
        className={className}
        onClick={() => {
          props.press(i);
        }}
      />
    );

    indicators.push(item);
  }

  return <div className={`${classes.indicators}`}>{indicators}</div>;
};

export default HomeCarousel;
