import React, { useEffect, useRef, useCallback } from 'react';
import RichText from '../components/RichText';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Link as GatsbyLink } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import {
  signInDialogOpenState,
  newEnquiryDialogOpenState,
} from '../../common/States';
import { useSetRecoilState } from 'recoil';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles(theme => ({
  root: {},
  holder: {
    flexDirection: props => (props.showImageOnRight ? 'row-reverse' : 'row'),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      flexDirection: 'column',
    },
  },
  subHolder: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  subTextHolder: {
    width: '50%',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  image: {
    width: '90%',
    [theme.breakpoints.up('xs')]: {
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      width: '90%',
    },
  },
  buttonLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  button: {
    minWidth: '40%',
    [theme.breakpoints.down('xs')]: {
      minWidth: '60%',
    },
  },
}));

export default React.memo(({ data }) => {
  const showImageOnRight = data.primary.show_image_on_right;
  const classes = useStyles({ showImageOnRight });
  const imageUrl = data.primary.image ? data.primary.image.url : '';
  const backgroundColor = data.primary.background_color;
  const textColor = data.primary.text_color;
  const buttonAlign = data.primary.button_align;
  const buttonColor = data.primary.button_color;
  const buttonLinkType = data.primary.button_link_type;
  const buttonText = data.primary.button_text;
  const buttonSize = data.primary.button_size;
  const buttonVariant = data.primary.button_variant;
  const text = data.primary.text;

  const textRef = useRef();
  const imageRef = useRef();

  const setSignInDialogOpen = useSetRecoilState(signInDialogOpenState);
  const setNewEnquiryDialogOpen = useSetRecoilState(newEnquiryDialogOpenState);

  const callToAction = useCallback(callToActionType => {
    console.log(callToActionType);
    switch (callToActionType) {
      case 'Traveller Sign In':
        setSignInDialogOpen(true);
        break;
      case 'Traveller Sign Up':
        setSignInDialogOpen(true);
        break;
      case 'Enquiry':
        setNewEnquiryDialogOpen(true);
        break;
      default:
        console.log('Unknown call to action ' + callToActionType);
    }
  }, []);

  useEffect(() => {
    let tl = gsap.timeline({
      // yes, we can add it to an entire timeline!
      scrollTrigger: {
        trigger: textRef.current,
        pin: false, // pin the trigger element while active
        start: 'top bottom', // when the top of the trigger hits the top of the viewport
        end: 'center center', // end after scrolling 500px beyond the start
        scrub: true, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
      },
    });
    gsap.set(imageRef.current, {
      transformOrigin: '50% 50%',
    });
    tl.from(
      textRef.current,
      {
        x: showImageOnRight ? '-100vw' : '100vw',
        autoAlpha: 0,
      },
      0
    ).from(
      imageRef.current,
      {
        scale: 0.5,
        autoAlpha: 0,
      },
      0
    );
  }, [textRef]);
  return (
    <Box bgcolor={backgroundColor} py={5} overflow="hidden">
      <Container maxWidth="lg">
        <Box display="flex" className={classes.holder}>
          <Box
            className={classes.subHolder}
            display="flex"
            flexGrow={1}
            flexShrink={0}
            alignItems="center"
            justifyContent="center"
            my="auto"
            width="100%"
            py={3}
            overflow="visible"
            textAlign="center"
          >
            {!showImageOnRight && (
              <img
                width="80%"
                alt={<RichText renderAsText={text} />}
                src={imageUrl}
                ref={imageRef}
                className={classes.image}
              />
            )}
            {showImageOnRight && (
              <img
                width="80%"
                alt={<RichText renderAsText={text} />}
                src={imageUrl}
                ref={imageRef}
                className={classes.image}
              />
            )}
          </Box>
          <Box
            display="flex"
            flexGrow={1}
            flexShrink={0}
            className={classes.subTextHolder}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            ref={textRef}
          >
            <Box color={textColor} py={3}>
              <RichText render={text} />
            </Box>
            {!!buttonText && (
              <Box textAlign={buttonAlign} width="100%">
                <Button
                  size={buttonSize}
                  variant={buttonVariant}
                  color={buttonColor}
                  className={classes.button}
                  onClick={() => {
                    callToAction(buttonLinkType);
                  }}
                >
                  {buttonText}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
});
