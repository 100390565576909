import React from 'react';
import RichText from '../components/RichText';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  heading: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  text: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export default React.memo(({ data }) => {
  const classes = useStyles();
  return (
    <Box>
      <Grid container spacing={0} justify="center">
        {data.fields.map((item, index) => {
          const textAlign = item.align_text;
          const backgroundColor = item.background_color;
          const heading = item.heading;
          const imageUrl = !!item.image ? item.image.url : '';
          const text = item.text;
          const textColor = item.text_color;
          return (
            <Grid key={index} item xs={12} sm={6} lg={3}>
              <Box
                textAlign={textAlign}
                display="flex"
                flexDirection="column"
                bgcolor={backgroundColor}
                height="100%"
              >
                <Box pt={7}>
                  <img
                    width={80}
                    style={{ maxHeight: 85 }}
                    alt={<RichText renderAsText={heading.text} />}
                    src={imageUrl}
                  />
                </Box>
                <Box
                  maxWidth={300}
                  className={classes.text}
                  pt={3}
                  pb={1}
                  color={textColor}
                  mt={2}
                  mb={1}
                >
                  <RichText render={heading} />
                </Box>
                <Box
                  maxWidth={200}
                  className={classes.text}
                  pb={7}
                  color={textColor}
                >
                  <RichText render={text} />
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
});
