import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Link as GatsbyLink } from 'gatsby';
import { Background, buildURL } from 'react-imgix';
import Carousel from '../components/Carousel';

const useStyles = makeStyles(theme => ({
  overlay: {
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
  },
  backgroundImage: {
    position: 'relative',
  },
}));

export default React.memo(({ data }) => {
  const { fields, primary } = data;
  const {
    animation_type,
    auto_play,
    interval,
    show_indicators,
    min_height,
  } = primary;
  const classes = useStyles();
  return (
    <Carousel
      data={fields}
      min_height={min_height}
      animation_type={animation_type}
      auto_play={auto_play}
      interval={interval}
      show_indicators={show_indicators}
    />
  );
});
