import React, { useRef, useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { Background, buildURL } from 'react-imgix';
import RichText from '../RichText';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Link as GatsbyLink } from 'gatsby';
import gsap from 'gsap';
import {
  signInDialogOpenState,
  newEnquiryDialogOpenState,
} from '../../../common/States';
import { useSetRecoilState } from 'recoil';
import CSSPlugin from 'gsap/CSSPlugin';
gsap.registerPlugin(CSSPlugin);

const useStyles = makeStyles(theme => ({
  overlay: {
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
  },
  backgroundImage: {
    position: 'absolute',
    display: 'block',
    width: '100%',
    height: '100%',
    top: 0,
    opacity: 0,
  },
  indicators: {
    width: '100%',
    marginTop: '10px',
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
  },
  indicator: {
    fontSize: '15px',
    cursor: 'pointer',
    transition: '200ms',
    color: '#afafaf',
    '&:hover': {
      color: '#1f1f1f',
    },
    '&:active': {
      color: '#1f1f1f',
    },
  },
  active: {
    color: '#494949',
  },
  buttonWrapper: {
    position: 'absolute',
    height: '100px',
    backgroundColor: 'transparent',
    top: 'calc(50% - 70px)',
    '&:hover': {
      '& $button': {
        backgroundColor: 'black',
        filter: 'brightness(120%)',
        opacity: 0.4,
      },
    },
  },
  fullHeightHoverWrapper: {
    height: 'calc(100% - 20px - 10px) !important',
    top: '0 !important',
  },
  button: {
    margin: '0 10px',
    position: 'relative',
    backgroundColor: '#494949',
    top: '30px',
    color: 'white',
    fontSize: '30px',
    transition: '200ms',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.6 !important',
    },
    zIndex: 1,
  },
  fullHeightHoverButton: {
    top: 'calc(50% - 20px) !important',
  },
  buttonVisible: {
    opacity: '0.6',
  },
  buttonHidden: {
    opacity: '0',
  },
  next: {
    right: 0,
  },
  prev: {
    left: 0,
  },
}));

const Carousel = ({
  data,
  min_height,
  interval,
  animation_type,
  auto_play,
  show_indicators,
}) => {
  const classes = useStyles();
  const slidesRef = useRef([]);

  const currentSlide = useRef(0);

  const [active, setActive] = useState(0);

  const buttonCssClassValue = `${classes.button} ${classes.buttonVisible}`;
  const buttonWrapperCssClassValue = `${classes.buttonWrapper}`;
  const timer = useRef(null);
  const container = useRef(null);

  const setSignInDialogOpen = useSetRecoilState(signInDialogOpenState);
  const setNewEnquiryDialogOpen = useSetRecoilState(newEnquiryDialogOpenState);

  const start = useCallback(() => {
    if (!auto_play) {
      return;
    }
    clearTimeout(timer.current);
    if (animation_type === 'slide') {
      timer.current = setInterval(() => {
        gsap.to(slidesRef.current[currentSlide.current], {
          xPercent: -100,
          duration: 2,
          ease: 'power2.out',
        });
        currentSlide.current =
          (currentSlide.current + 1) % slidesRef.current.length;
        gsap.set(slidesRef.current[currentSlide.current], {
          opacity: 1,
          xPercent: 100,
        });
        gsap.to(slidesRef.current[currentSlide.current], {
          xPercent: 0,
          duration: 2,
          ease: 'power2.out',
        });
        setActive(currentSlide.current);
      }, interval + 2000);
    } else if (animation_type === 'fade') {
      timer.current = setInterval(() => {
        gsap.to(slidesRef.current[currentSlide.current], {
          opacity: 0,
          duration: 2,
        });
        currentSlide.current =
          (currentSlide.current + 1) % slidesRef.current.length;
        gsap.to(slidesRef.current[currentSlide.current], {
          opacity: 1,
          duration: 2,
        });
        setActive(currentSlide.current);
      }, interval + 2000);
    }

    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const next = useCallback(() => {
    pause();
    if (animation_type === 'slide') {
      gsap.to(slidesRef.current[currentSlide.current], {
        xPercent: -100,
        duration: 1,
        ease: 'power2.out',
      });
      currentSlide.current =
        (currentSlide.current + 1) % slidesRef.current.length;
      gsap.set(slidesRef.current[currentSlide.current], {
        opacity: 1,
        xPercent: 100,
      });
      gsap.to(slidesRef.current[currentSlide.current], {
        xPercent: 0,
        duration: 1,
        ease: 'power2.out',
      });
      setActive(currentSlide.current);
    } else if (animation_type === 'fade') {
      gsap.to(slidesRef.current[currentSlide.current], {
        opacity: 0,
        duration: 1,
      });
      currentSlide.current =
        (currentSlide.current + 1) % slidesRef.current.length;
      gsap.to(slidesRef.current[currentSlide.current], {
        opacity: 1,
        duration: 1,
      });
      setActive(currentSlide.current);
    }
  });

  const previous = useCallback(() => {
    pause();
    if (animation_type === 'slide') {
      gsap.to(slidesRef.current[currentSlide.current], {
        xPercent: 100,
        duration: 1,
        ease: 'power2.out',
      });
      currentSlide.current =
        (currentSlide.current + slidesRef.current.length - 1) %
        slidesRef.current.length;
      gsap.set(slidesRef.current[currentSlide.current], {
        opacity: 1,
        xPercent: -100,
      });
      gsap.to(slidesRef.current[currentSlide.current], {
        xPercent: 0,
        duration: 1,
        ease: 'power2.out',
      });
      setActive(currentSlide.current);
    } else if (animation_type === 'fade') {
      gsap.to(slidesRef.current[currentSlide.current], {
        opacity: 0,
        duration: 1,
      });
      currentSlide.current =
        (currentSlide.current + slidesRef.current.length - 1) %
        slidesRef.current.length;
      gsap.to(slidesRef.current[currentSlide.current], {
        opacity: 1,
        duration: 1,
      });
      setActive(currentSlide.current);
    }
  });

  const pause = useCallback(() => {
    clearTimeout(timer.current);
  }, []);

  const gotTo = useCallback(index => {
    if (index !== currentSlide.current) {
      pause();
      if (animation_type === 'slide') {
        const previousIndex = currentSlide.current;

        gsap.to(slidesRef.current[currentSlide.current], {
          xPercent: index < previousIndex ? 100 : -100,
          duration: 1,
          ease: 'power2.out',
        });
        currentSlide.current = index;
        gsap.set(slidesRef.current[currentSlide.current], {
          opacity: 1,
          xPercent: index < previousIndex ? -100 : 100,
        });
        gsap.to(slidesRef.current[currentSlide.current], {
          xPercent: 0,
          duration: 1,
          ease: 'power2.out',
        });
        setActive(currentSlide.current);
      } else if (animation_type === 'fade') {
        gsap.to(slidesRef.current[currentSlide.current], {
          opacity: 0,
          duration: 1,
        });
        currentSlide.current = index;
        gsap.to(slidesRef.current[currentSlide.current], {
          opacity: 1,
          duration: 1,
        });
        setActive(currentSlide.current);
      }
    }
  });

  const callToAction = useCallback(callToActionType => {
    console.log(callToActionType);
    switch (callToActionType) {
      case 'Traveller Sign In':
        setSignInDialogOpen(true);
        break;
      case 'Traveller Sign Up':
        setSignInDialogOpen(true);
        break;
      case 'Enquiry':
        setNewEnquiryDialogOpen(true);
        break;
      default:
        console.log('Unknown call to action ' + callToActionType);
    }
  }, []);

  useEffect(() => {
    start();
    container.current.addEventListener(
      'mouseover',
      function (event) {
        pause();
      },
      false
    );
    container.current.addEventListener(
      'mouseleave',
      function (event) {
        start();
      },
      false
    );
  }, []);

  return (
    <Box ref={container} position="relative" minHeight={min_height}>
      {data.map((item, index) => {
        const {
          align_text,
          button_align,
          button_color,
          button_size,
          button_text,
          button_variant,
          image,
          image_object_position,
          image_overlay_color,
          image_overlay_opacity,
          justify_content,
          text,
          text_color,
          button_link_type,
        } = item;
        return (
          <Background
            key={'CarouselCard' + index}
            src={image.url}
            className={classes.backgroundImage}
            sizes={min_height}
            ref={el => {
              slidesRef.current[index] = el === null ? null : el._node;
            }}
            htmlAttributes={{
              style: { opacity: index === 0 ? 1 : 0 },
            }}
          >
            <Box position="relative" zIndex={1}>
              <Container maxWidth="md">
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent={justify_content}
                  alignItems="center"
                  minHeight={min_height}
                >
                  <Box textAlign={align_text} color={text_color}>
                    <RichText render={text} />
                  </Box>

                  {!!button_text && (
                    <Box textAlign={button_align} pt={4} width="100%">
                      <Button
                        size={button_size}
                        variant={button_variant}
                        color={button_color}
                        onClick={() => {
                          callToAction(button_link_type);
                        }}
                      >
                        {button_text}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Container>
            </Box>
            <Box
              zIndex={0}
              className={classes.overlay}
              style={{
                backgroundColor: !!image_overlay_color
                  ? `${image_overlay_color}`
                  : '',
                opacity:
                  image_overlay_opacity == null
                    ? 0
                    : image_overlay_opacity * 0.01,
              }}
            ></Box>
          </Background>
        );
      })}
      <div className={`${buttonWrapperCssClassValue} ${classes.next}`}>
        <IconButton
          className={`${buttonCssClassValue} ${classes.next}`}
          onClick={next}
          aria-label="Next"
        >
          <NavigateNextIcon />
        </IconButton>
      </div>

      <div className={`${buttonWrapperCssClassValue} ${classes.prev}`}>
        <IconButton
          className={`${buttonCssClassValue}  ${classes.prev}`}
          onClick={previous}
          aria-label="Previous"
        >
          <NavigateBeforeIcon />
        </IconButton>
      </div>

      {show_indicators && (
        <Indicators
          classes={classes}
          length={data.length}
          active={active}
          press={index => {
            gotTo(index);
          }}
        />
      )}
    </Box>
  );
};

const Indicators = props => {
  const classes = props.classes;

  let indicators = [];
  for (let i = 0; i < props.length; i++) {
    const className =
      i === props.active
        ? `${classes.indicator} ${classes.active}`
        : `${classes.indicator}`;
    const item = (
      <FiberManualRecordIcon
        key={i}
        size="small"
        className={className}
        onClick={() => {
          props.press(i);
        }}
      />
    );

    indicators.push(item);
  }

  return <div className={`${classes.indicators}`}>{indicators}</div>;
};

export default Carousel;
