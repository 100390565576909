import React from 'react';
import RichText from '../components/RichText';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    paddingLeft: '3em',
    paddingRight: '3em',
    '& p': {
      lineHeight: '2em',
      marginBottom: 0,
    },
    '& h3': {
      marginBottom: 0,
    },
    '& h4': {
      marginBottom: 0,
    },
    '& h5': {
      marginBottom: 0,
    },
    '& h6': {
      marginBottom: 0,
    },
  },
}));

export default React.memo(({ data }) => {
  const classes = useStyles();
  return (
    <Box
      display={{
        xs: data.primary.show_on_mobile ? 'block' : 'none',
        md: 'block',
      }}
      bgcolor={data.primary.background_color}
      color={data.primary.text_color}
      className={classes.root}
      py={data.primary.padding == null ? 2 : data.primary.padding}
    >
      <RichText render={data.primary.content} />
    </Box>
  );
});
