import React, { useEffect } from 'react';
import RichText from '../components/RichText';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles(theme => ({
  root: {},
  cardText: {},
  heading: {},
  cardHeading: {},
  footer: {},
  cardImage: {},
}));

const InformationGrid = ({ data }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const revealRatio = mdDown ? 0.1 : 0.66;
  const backgroundColor = data.primary.background_color;
  const heading = data.primary.heading;
  const footer = data.primary.footer;
  const textColor = data.primary.textColor;
  useEffect(() => {
    gsap.set(`.${classes.cardImage}`, {
      transformOrigin: '50% 50%',
      scale: 0.0,
    });
    gsap.set(`.${classes.heading}`, {
      y: -100,
      opacity: 0,
    });
    ScrollTrigger.batch(`.${classes.cardImage}`, {
      onEnter: batch => {
        gsap.to(batch, {
          scale: 1.0,
          stagger: 0.2,
          ease: 'back',
          duration: 2,
          overwrite: true,
        });
      },
      onEnterBack: batch => {
        gsap.to(batch, {
          scale: 1.0,
          stagger: 0.2,
          ease: 'back',
          duration: 2,
          overwrite: true,
        });
      },
      onLeave: batch => {
        gsap.to(batch, {
          scale: 0,
          stagger: 0.2,
          ease: 'back',
          duration: 2,
          overwrite: true,
        });
      },
      onLeaveBack: batch => {
        gsap.to(batch, {
          scale: 0,
          stagger: 0.2,
          ease: 'back',
          duration: 2,
          overwrite: true,
        });
      },
    });
    gsap.to(`.${classes.heading}`, {
      y: 0,
      opacity: 1,
      scrollTrigger: {
        trigger: `.${classes.heading}`,
        start: 'top 75%',
      },
      delay: 1,
      duration: 2,
      ease: 'back',
    });
  }, []);
  return (
    <Box className={classes.root} bgcolor={backgroundColor} py={3}>
      <Container maxWidth="lg">
        {heading && (
          <Box
            className={classes.heading}
            color={textColor}
            pb={5}
            textAlign="center"
          >
            <RichText render={heading} />
          </Box>
        )}
        <Grid container spacing={5} justify="center">
          {data.fields.map((item, index) => {
            const textAlign = item.align_text;
            const heading = item.heading;
            const headingColor = item.heading_color;
            const cropImage = item.crop_image;
            const imageUrl = !!item.image
              ? item.image.url +
                (cropImage ? '&mask=ellipse&w=200&h=200&fit=crop' : '')
              : '';
            const text = item.text;
            const textColor = item.text_color;
            return (
              <Grid key={index} item xs={12} md={4}>
                <Box
                  textAlign={textAlign}
                  display="flex"
                  flexDirection="column"
                >
                  {imageUrl && (
                    <Box>
                      <img
                        width={150}
                        style={{ maxHeight: 150 }}
                        alt={<RichText renderAsText={heading.text} />}
                        src={imageUrl}
                        className={classes.cardImage}
                      />
                    </Box>
                  )}
                  <Box
                    className={classes.cardHeading}
                    color={headingColor}
                    mt={2}
                    mb={1}
                  >
                    <RichText render={heading} />
                  </Box>
                  <Box className={classes.cardText} color={textColor} mx={4}>
                    <RichText render={text} />
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Container>
      {footer && (
        <Container maxWidth="lg">
          <Box className={classes.footer} color={textColor} textAlign="center">
            <RichText render={footer} />
          </Box>
        </Container>
      )}
    </Box>
  );
};

export default InformationGrid;
