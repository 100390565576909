import React from 'react';
import HomeCarousel from '../components/HomeCarousel';

const HomeCarouselSection = ({ data }) => {
  const { fields, primary } = data;
  const {
    animation_type,
    auto_play,
    interval,
    show_indicators,
    min_height,
    repeat_image,
    with_login_type,
    default_height,
  } = primary;
  return (
    <HomeCarousel
      data={fields}
      repeat_image={repeat_image}
      min_height={min_height}
      animation_type={animation_type}
      auto_play={auto_play}
      interval={interval}
      show_indicators={show_indicators}
      with_login_type={with_login_type}
      default_height={default_height}
    />
  );
};

export default React.memo(HomeCarouselSection);
