import React from 'react';
import RichText from '../components/RichText';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  overlay: {
    display: 'block',
    backgroundColor: props => {
      return !!props.backgroundOverlayColor
        ? `${props.backgroundOverlayColor}`
        : '';
    },
    zIndex: 1,
    opacity: 0.1,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
  },
  image: {
    [theme.breakpoints.up('md')]: {
      backgroundImage: props => {
        return !!props.backgroundImage
          ? `url(${props.backgroundImage.url})`
          : '';
      },
    },
    backgroundSize: 'contain',
    display: 'block',
    opacity: props => {
      return !!props.backgroundOverlayOpacity
        ? props.backgroundOverlayOpacity
        : 1;
    },
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
  },
}));

export default React.memo(({ data }) => {
  const { background_overlay_color, background_overlay_opacity } = data.primary;
  const classes = useStyles({
    backgroundImage: data.primary.background_image,
    backgroundOverlayColor: background_overlay_color,
    backgroundOverlayOpacity: background_overlay_opacity * 0.01,
  });
  return (
    <Box
      bgcolor={data.primary.background_color}
      color={data.primary.text_color}
      className={classes.root}
      position="relative"
      py={5}
    >
      <Box zIndex={0} className={classes.image}></Box>
      <Container maxWidth="md">
        <RichText render={data.primary.text} />
      </Container>
      <Box zIndex={0} className={classes.overlay}></Box>
    </Box>
  );
});
