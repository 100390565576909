import React from 'react';
import RichText from '../components/RichText';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& p': {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    '& h1': {
      marginBottom: 0,
    },
    '& h2': {
      marginBottom: 0,
    },
    '& h3': {
      marginBottom: 0,
    },
    '& h4': {
      marginBottom: 0,
    },
    '& h5': {
      marginBottom: 0,
    },
    '& h6': {
      marginBottom: 0,
    },
  },
}));

export default React.memo(({ data }) => {
  const classes = useStyles();
  const { background_color, padding } = data.primary;
  const { fields } = data;
  return (
    <Box
      bgcolor={background_color}
      className={classes.root}
      py={padding == null ? 2 : padding}
      px={{ lg: 10 }}
    >
      {fields.map((item, index) => {
        const { align_text, padding, text, text_color, max_width } = item;
        return (
          <Container maxWidth={max_width}>
            <Box
              className={classes.line}
              textAlign={align_text}
              py={padding == null ? 2 : padding}
              px={3}
              color={text_color}
              key={index}
            >
              <RichText render={text} />
            </Box>
          </Container>
        );
      })}
    </Box>
  );
});
